/*----------------------------------------------------
@File: Default Styles
@Author: Rocky Ahmed
@URL: http://wethemez.com
Author E-mail: rockybd1995@gmail.com

This file contains the styling for the actual theme, this
is the file you need to edit to change the look of the
theme.
---------------------------------------------------- */
/*=====================================================================
@Template Name: Builder Construction 
@Author: Rocky Ahmed
@Developed By: Rocky Ahmed
@Developer URL: http://rocky.wethemez.com
Author E-mail: rockybd1995@gmail.com

@Default Styles

Table of Content:
01/ Variables
02/ predefin
03/ header
04/ button
05/ banner
06/ breadcrumb
07/ about
08/ team
09/ project 
10/ price 
11/ team 
12/ blog 
13/ video  
14/ features  
15/ career  
16/ contact 
17/ footer

=====================================================================*/
/*----------------------------------------------------*/
/*font Variables*/
/*Color Variables*/
/*=================== fonts ====================*/
@import url("https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700|Roboto:300,400,500,700");
/*---------------------------------------------------- */
/*----------------------------------------------------*/
.list {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

a {
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

a:hover, a:focus {
  text-decoration: none;
  outline: none;
}

.row.m0 {
  margin: 0px;
}

body {
  line-height: 24px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  color: #777777;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

button:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.p_120 {
  padding-top: 120px;
  padding-bottom: 120px;
}

.p0 {
  padding-left: 0px;
  padding-right: 0px;
  overflow: hidden;
}

.pad_top {
  padding-top: 120px;
}

.mt-25 {
  margin-top: 25px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

@media (min-width: 1620px) {
  .box_1620 {
    max-width: 1650px;
    margin: auto;
  }
}


.bannerf{
  display: flex;
  justify-content: center;
  margin-bottom: 12%;
}

@media(max-width:480px){
  .productimage{
    min-height: 400px;
    min-width: 400px;
  }
}














/* Add this CSS to your stylesheet */
.gallery_filter a {
  color: black; /* Default color */
  text-decoration: none;
  }
  
  .gallery_filter a:hover {
  color: blue; /* Color when hovered */
  }
  
  .gallery_filter a:active {
  color: red; /* Color when clicked */
  }




/* Main Title Area css
============================================================================================ */
.main_title {
  text-align: center;
  max-width: 720px;
  margin: 0px auto 75px;
}

.main_title h2 {
  font-family: "Roboto", sans-serif;
  font-size: 36px;
  color: #222222;
  margin-bottom: 12px;
}

.main_title p {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  line-height: 24px;
  color: #777777;
  margin-bottom: 0px;
}

/* End Main Title Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
.header_area {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  -webkit-transition: background 0.4s, all 0.3s linear;
  -o-transition: background 0.4s, all 0.3s linear;
  transition: background 0.4s, all 0.3s linear;
}

.header_area .navbar {
  background: transparent;
  padding: 0px;
  border: 0px;
  border-radius: 0px;
}

.header_area .navbar .nav .nav-item {
  margin-right: 45px;
}

.header_area .navbar .nav .nav-item .nav-link {
  font: 500 12px/100px "Roboto", sans-serif;
  text-transform: uppercase;
  color: #222222;
  padding: 0px;
  display: inline-block;
}

.header_area .navbar .nav .nav-item .nav-link:after {
  display: none;
}

.header_area .navbar .nav .nav-item:hover .nav-link, .header_area .navbar .nav .nav-item.active .nav-link {
  color: #8d83ff;
}

.header_area .navbar .nav .nav-item.submenu {
  position: relative;
}

.header_area .navbar .nav .nav-item.submenu ul {
  border: none;
  padding: 0px;
  border-radius: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0px;
  background: #fff;
}

.link-button {
  background: none;
  color: blue;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  text-decoration: underline;
  }
  
  .link-button:hover {
  color: darkblue;
  }



@media (min-width: 992px) {
  .header_area .navbar .nav .nav-item.submenu ul {
    position: absolute;
    top: 120%;
    left: 0px;
    min-width: 200px;
    text-align: left;
    opacity: 0;
    -webkit-transition: all 300ms ease-in;
    -o-transition: all 300ms ease-in;
    transition: all 300ms ease-in;
    visibility: hidden;
    display: block;
    border: none;
    padding: 0px;
    border-radius: 0px;
  }
}

.header_area .navbar .nav .nav-item.submenu ul:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #eeeeee transparent transparent transparent;
  position: absolute;
  right: 24px;
  top: 45px;
  z-index: 3;
  opacity: 0;
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;
}

.header_area .navbar .nav .nav-item.submenu ul .nav-item {
  display: block;
  float: none;
  margin-right: 0px;
  border-bottom: 1px solid #ededed;
  margin-left: 0px;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.header_area .navbar .nav .nav-item.submenu ul .nav-item .nav-link {
  line-height: 45px;
  color: #222222;
  padding: 0px 30px;
  -webkit-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  transition: all 150ms linear;
  display: block;
  margin-right: 0px;
}

.header_area .navbar .nav .nav-item.submenu ul .nav-item:last-child {
  border-bottom: none;
}

.header_area .navbar .nav .nav-item.submenu ul .nav-item:hover .nav-link {
  background: #8d83ff;
  color: #fff;
}

@media (min-width: 992px) {
  .header_area .navbar .nav .nav-item.submenu:hover ul {
    visibility: visible;
    opacity: 1;
    top: 100%;
  }
}

.header_area .navbar .nav .nav-item.submenu:hover ul .nav-item {
  margin-top: 0px;
}

.header_area .navbar .nav .nav-item:last-child {
  margin-right: 0px;
}

.header_area .navbar .search {
  font-size: 12px;
  line-height: 60px;
  display: inline-block;
  color: #222222;
  margin-left: 220px;
}

.header_area .navbar .search i {
  font-weight: 600;
}

.header_area + section, .header_area + row, .header_area + div {
  margin-top: 100px;
}

.header_area.navbar_fixed .main_menu {
  position: fixed;
  width: 100%;
  top: -70px;
  left: 0;
  right: 0;
  background: #fff;
  -webkit-transform: translateY(70px);
  -ms-transform: translateY(70px);
  transform: translateY(70px);
  -webkit-transition: background 500ms ease, -webkit-transform 500ms ease;
  transition: background 500ms ease, -webkit-transform 500ms ease;
  -o-transition: transform 500ms ease, background 500ms ease;
  transition: transform 500ms ease, background 500ms ease;
  transition: transform 500ms ease, background 500ms ease, -webkit-transform 500ms ease;
  -webkit-transition: transform 500ms ease, background 500ms ease;
  -webkit-box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
}

.header_area.navbar_fixed .main_menu .navbar .nav .nav-item .nav-link {
  line-height: 70px;
}

.top_menu {
  border-bottom: 1px solid #eeeeee;
}

.top_menu .header_social li {
  display: inline-block;
  margin-right: 15px;
}

.top_menu .header_social li a {
  font-size: 12px;
  color: #ccc;
  display: inline-block;
  line-height: 45px;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}

.top_menu .header_social li:last-child {
  margin-right: 0px;
}

.top_menu .header_social li:hover a {
  color: #8d83ff;
}

.top_menu .ac_btn {
  line-height: 28px;
  border: 1px solid #eeeeee;
  display: inline-block;
  background: #f9f9ff;
  padding: 0px 19px;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #777777;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
  margin-right: 1px;
  margin-top: 8px;
}

.top_menu .ac_btn:hover {
  background: #8d83ff;
  color: #fff;
  border-color: #8d83ff;
}

.top_menu .dn_btn {
  line-height: 28px;
  border: 1px solid #8d83ff;
  display: inline-block;
  background: #8d83ff;
  padding: 0px 19px;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #fff;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
  margin-top: 8px;
  border-radius: 3px;
}

.top_menu .dn_btn:hover {
  background: transparent;
  color: #222222;
  border-color: #8d83ff;
}

.top_menu .lan_pack {
  height: 30px;
  border: 1px solid #eeeeee;
  border-radius: 3px;
  line-height: 28px;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  padding-left: 19px;
  padding-right: 36px;
  color: #777777;
  background: #f9f9ff;
  margin-right: 5px;
  margin-top: 8px;
}

.top_menu .lan_pack .current {
  color: #777777;
}

.top_menu .lan_pack:after {
  content: "\f0d7";
  border: none !important;
  font: normal normal normal 12px/1 FontAwesome;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  height: auto;
  margin-top: -6px;
  right: 20px;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Home Banner Area css
============================================================================================ */
.home_banner_area {
  position: relative;
  z-index: 1;
  min-height: 800px;
}

.home_banner_area .banner_inner {
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 800px;
}

.home_banner_area .banner_inner .overlay {
  background: url(./img/banner/home-banner.jpg) no-repeat scroll center center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 121%;
  bottom: 0;
  z-index: -1;
}

.home_banner_area .banner_inner .banner_content {
  color: #fff;
}

.home_banner_area .banner_inner .banner_content h5 {
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 0px;
  letter-spacing: 2.1px;
}

.home_banner_area .banner_inner .banner_content h3 {
  font-size: 36px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 45px;
  margin-bottom: 25px;
}

.home_banner_area .banner_inner .banner_content p {
  font-size: 14px;
  line-height: 24px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  max-width: 500px;
  margin: auto;
}

.home_banner_area .banner_inner .banner_content .org_btn {
  margin-right: 10px;
  margin-top: 45px;
}

.blog_banner {
  min-height: 780px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: 0px;
}

.blog_banner .banner_inner {
  background: #04091e;
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 780px;
  z-index: 1;
}

.blog_banner .banner_inner .overlay {
  background: url(./img/banner/banner-2.jpg) no-repeat scroll center center;
  opacity: .5;
  height: 125%;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  z-index: -1;
}

.blog_banner .banner_inner .blog_b_text {
  max-width: 700px;
  margin: auto;
  color: #fff;
  margin-top: 40px;
}

.blog_banner .banner_inner .blog_b_text h2 {
  font-size: 60px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  line-height: 66px;
  margin-bottom: 15px;
}

.blog_banner .banner_inner .blog_b_text p {
  font-size: 16px;
  margin-bottom: 35px;
}

.blog_banner .banner_inner .blog_b_text .white_bg_btn {
  line-height: 42px;
  padding: 0px 45px;
}

.banner_box {
  max-width: 1620px;
  margin: auto;
}

.banner_area {
  position: relative;
  z-index: 1;
  min-height: 300px;
}

.banner_area .banner_inner {
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 300px;
  background-image: -moz-linear-gradient(0deg, #e187f0 0%, #f27fd3 33%, #a276ff 67%, #8889ff 100%);
  background-image: -webkit-linear-gradient(0deg, #e187f0 0%, #f27fd3 33%, #a276ff 67%, #8889ff 100%);
  background-image: -ms-linear-gradient(0deg, #e187f0 0%, #f27fd3 33%, #a276ff 67%, #8889ff 100%);
  background-image: -webkit-linear-gradient(90deg, #0e51a5 0%, #1d3860 33%, #084298 67%, #0c0c38 100%);
  z-index: 1;
}

.banner_area .banner_inner .overlay {
  background: url(./img/banner/banner.jpg) no-repeat scroll center center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 125%;
  bottom: 0;
  z-index: -1;
  opacity: 1;
}

.banner_area .banner_inner .banner_content h2 {
  color: #fff;
  font-size: 48px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0px;
  font-weight: 500;
}

.banner_area .banner_inner .banner_content .page_link a {
  font-size: 14px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  margin-right: 32px;
  position: relative;
  text-transform: uppercase;
}

.banner_area .banner_inner .banner_content .page_link a:before {
  content: "\f0da";
  font: normal normal normal 14px/1 FontAwesome;
  position: absolute;
  right: -25px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.banner_area .banner_inner .banner_content .page_link a:last-child {
  margin-right: 0px;
}

.banner_area .banner_inner .banner_content .page_link a:last-child:before {
  display: none;
}

.banner_area .banner_inner .banner_content .page_link a:hover {
  color: #8d83ff;
}

/* End Home Banner Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Latest Blog Area css
============================================================================================ */
.l_blog_item .l_blog_text .date {
  margin-top: 24px;
  margin-bottom: 15px;
}

.l_blog_item .l_blog_text .date a {
  color: #777777;
  font-size: 12px;
}

.l_blog_item .l_blog_text h4 {
  font-size: 18px;
  color: #222222;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 0px;
  padding-bottom: 20px;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}

.l_blog_item .l_blog_text h4:hover {
  color: #8d83ff;
}

.l_blog_item .l_blog_text p {
  margin-bottom: 0px;
  padding-top: 20px;
}

/* End Latest Blog Area css
============================================================================================ */
/* Causes Area css
============================================================================================ */
.causes_slider .owl-dots {
  text-align: center;
  margin-top: 80px;
}

.causes_slider .owl-dots .owl-dot {
  height: 14px;
  width: 14px;
  background: #eeeeee;
  display: inline-block;
  margin-right: 7px;
}

.causes_slider .owl-dots .owl-dot:last-child {
  margin-right: 0px;
}

.causes_slider .owl-dots .owl-dot.active {
  background: #8d83ff;
}

.causes_item {
  background: #fff;
}

.causes_item .causes_img {
  position: relative;
}

.causes_item .causes_img .c_parcent {
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0px;
  height: 3px;
  background: rgba(255, 255, 255, 0.5);
}

.causes_item .causes_img .c_parcent span {
  width: 70%;
  height: 3px;
  background: #8d83ff;
  position: absolute;
  left: 0px;
  bottom: 0px;
}

.causes_item .causes_img .c_parcent span:before {
  content: "75%";
  position: absolute;
  right: -10px;
  bottom: 0px;
  background: #8d83ff;
  color: #fff;
  padding: 0px 5px;
}

.causes_item .causes_text {
  padding: 30px 35px 40px 30px;
}

.causes_item .causes_text h4 {
  color: #222222;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  cursor: pointer;
}

.causes_item .causes_text h4:hover {
  color: #8d83ff;
}

.causes_item .causes_text p {
  font-size: 14px;
  line-height: 24px;
  color: #777777;
  font-weight: 300;
  margin-bottom: 0px;
}

.causes_item .causes_bottom a {
  width: 50%;
  border: 1px solid #8d83ff;
  text-align: center;
  float: left;
  line-height: 50px;
  background: #8d83ff;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.causes_item .causes_bottom a + a {
  border-color: #eeeeee;
  background: #fff;
  font-size: 14px;
  color: #222222;
}

/* End Causes Area css
============================================================================================ */
/*================= latest_blog_area css =============*/
.latest_blog_area {
  background: #f9f9ff;
}

.single-recent-blog-post {
  margin-bottom: 30px;
}

.single-recent-blog-post .thumb {
  overflow: hidden;
}

.single-recent-blog-post .thumb img {
  -webkit-transition: all 0.7s linear;
  -o-transition: all 0.7s linear;
  transition: all 0.7s linear;
}

.single-recent-blog-post .details {
  padding-top: 30px;
}

.single-recent-blog-post .details .sec_h4 {
  line-height: 24px;
  padding: 10px 0px 13px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.single-recent-blog-post .details .sec_h4:hover {
  color: #777777;
}

.single-recent-blog-post .date {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

.single-recent-blog-post:hover img {
  -webkit-transform: scale(1.23) rotate(10deg);
  -ms-transform: scale(1.23) rotate(10deg);
  transform: scale(1.23) rotate(10deg);
}

.tags .tag_btn {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  border: 1px solid #eeeeee;
  display: inline-block;
  padding: 1px 18px;
  text-align: center;
  color: #222222;
}

.tags .tag_btn:before {
  background: #8d83ff;
}

.tags .tag_btn + .tag_btn {
  margin-left: 2px;
}

/*========= blog_categorie_area css ===========*/
.blog_categorie_area {
  padding-top: 80px;
  padding-bottom: 80px;
}

.categories_post {
  position: relative;
  text-align: center;
  cursor: pointer;
}

.categories_post img {
  max-width: 100%;
}

.categories_post .categories_details {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: rgba(34, 34, 34, 0.8);
  color: #fff;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.categories_post .categories_details h5 {
  margin-bottom: 0px;
  font-size: 18px;
  line-height: 26px;
  text-transform: uppercase;
  color: #fff;
  position: relative;
}

.categories_post .categories_details p {
  font-weight: 300;
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 0px;
}

.categories_post .categories_details .border_line {
  margin: 10px 0px;
  background: #fff;
  width: 100%;
  height: 1px;
}

.categories_post:hover .categories_details {
  background: rgba(141, 131, 255, 0.85);
}

/*============ blog_left_sidebar css ==============*/
.blog_item {
  margin-bottom: 40px;
}

.blog_info {
  padding-top: 30px;
}

.blog_info .post_tag {
  padding-bottom: 20px;
}

.blog_info .post_tag a {
  font: 300 14px/21px "Roboto", sans-serif;
  color: #222222;
}

.blog_info .post_tag a:hover {
  color: #777777;
}

.blog_info .post_tag a.active {
  color: #8d83ff;
}

.blog_info .blog_meta li a {
  font: 300 14px/20px "Roboto", sans-serif;
  color: #777777;
  vertical-align: middle;
  padding-bottom: 12px;
  display: inline-block;
}

.blog_info .blog_meta li a i {
  color: #222222;
  font-size: 16px;
  font-weight: 600;
  padding-left: 15px;
  line-height: 20px;
  vertical-align: middle;
}

.blog_info .blog_meta li a:hover {
  color: #8d83ff;
}

.blog_post img {
  max-width: 100%;
}

.blog_details {
  padding-top: 20px;
}

.blog_details h2 {
  font-size: 24px;
  line-height: 36px;
  color: #222222;
  font-weight: 600;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.blog_details h2:hover {
  color: #8d83ff;
}

.blog_details p {
  margin-bottom: 26px;
}

.view_btn {
  font-size: 14px;
  line-height: 36px;
  display: inline-block;
  color: #222222;
  font-weight: 500;
  padding: 0px 30px;
  background: #fff;
}

.blog_right_sidebar {
  border: 1px solid #eeeeee;
  background: #fafaff;
  padding: 30px;
}

.blog_right_sidebar .widget_title {
  font-size: 18px;
  line-height: 25px;
  background: #8d83ff;
  text-align: center;
  color: #fff;
  padding: 8px 0px;
  margin-bottom: 30px;
}

.blog_right_sidebar .search_widget .input-group .form-control {
  font-size: 14px;
  line-height: 29px;
  border: 0px;
  width: 100%;
  font-weight: 300;
  color: #fff;
  padding-left: 20px;
  border-radius: 45px;
  z-index: 0;
  background: #8d83ff;
}

.blog_right_sidebar .search_widget .input-group .form-control.placeholder {
  color: #fff;
}

.blog_right_sidebar .search_widget .input-group .form-control:-moz-placeholder {
  color: #fff;
}

.blog_right_sidebar .search_widget .input-group .form-control::-moz-placeholder {
  color: #fff;
}

.blog_right_sidebar .search_widget .input-group .form-control::-webkit-input-placeholder {
  color: #fff;
}

.blog_right_sidebar .search_widget .input-group .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.blog_right_sidebar .search_widget .input-group .btn-default {
  position: absolute;
  right: 20px;
  background: transparent;
  border: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 14px;
  color: #fff;
  padding: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}

.blog_right_sidebar .author_widget {
  text-align: center;
}

.blog_right_sidebar .author_widget h4 {
  font-size: 18px;
  line-height: 20px;
  color: #222222;
  margin-bottom: 5px;
  margin-top: 30px;
}

.blog_right_sidebar .author_widget p {
  margin-bottom: 0px;
}

.blog_right_sidebar .author_widget .social_icon {
  padding: 7px 0px 15px;
}

.blog_right_sidebar .author_widget .social_icon a {
  font-size: 14px;
  color: #222222;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.blog_right_sidebar .author_widget .social_icon a + a {
  margin-left: 20px;
}

.blog_right_sidebar .author_widget .social_icon a:hover {
  color: #8d83ff;
}

.blog_right_sidebar .popular_post_widget .post_item .media-body {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-item-align: center;
  align-self: center;
  padding-left: 20px;
}

.blog_right_sidebar .popular_post_widget .post_item .media-body h3 {
  font-size: 14px;
  line-height: 20px;
  color: #222222;
  margin-bottom: 4px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.blog_right_sidebar .popular_post_widget .post_item .media-body h3:hover {
  color: #8d83ff;
}

.blog_right_sidebar .popular_post_widget .post_item .media-body p {
  font-size: 12px;
  line-height: 21px;
  margin-bottom: 0px;
}

.blog_right_sidebar .popular_post_widget .post_item + .post_item {
  margin-top: 20px;
}

.blog_right_sidebar .post_category_widget .cat-list li {
  border-bottom: 2px dotted #eee;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  padding-bottom: 12px;
}

.blog_right_sidebar .post_category_widget .cat-list li a {
  font-size: 14px;
  line-height: 20px;
  color: #777;
}

.blog_right_sidebar .post_category_widget .cat-list li a p {
  margin-bottom: 0px;
}

.blog_right_sidebar .post_category_widget .cat-list li + li {
  padding-top: 15px;
}

.blog_right_sidebar .post_category_widget .cat-list li:hover {
  border-color: #8d83ff;
}

.blog_right_sidebar .post_category_widget .cat-list li:hover a {
  color: #8d83ff;
}

.blog_right_sidebar .newsletter_widget {
  text-align: center;
}

.blog_right_sidebar .newsletter_widget .form-group {
  margin-bottom: 8px;
}

.blog_right_sidebar .newsletter_widget .input-group-prepend {
  margin-right: -1px;
}

.blog_right_sidebar .newsletter_widget .input-group-text {
  background: #fff;
  border-radius: 0px;
  vertical-align: top;
  font-size: 12px;
  line-height: 36px;
  padding: 0px 0px 0px 15px;
  border: 1px solid #eeeeee;
  border-right: 0px;
}

.blog_right_sidebar .newsletter_widget .form-control {
  font-size: 12px;
  line-height: 24px;
  color: #cccccc;
  border: 1px solid #eeeeee;
  border-left: 0px;
  border-radius: 0px;
}

.blog_right_sidebar .newsletter_widget .form-control.placeholder {
  color: #cccccc;
}

.blog_right_sidebar .newsletter_widget .form-control:-moz-placeholder {
  color: #cccccc;
}

.blog_right_sidebar .newsletter_widget .form-control::-moz-placeholder {
  color: #cccccc;
}

.blog_right_sidebar .newsletter_widget .form-control::-webkit-input-placeholder {
  color: #cccccc;
}

.blog_right_sidebar .newsletter_widget .form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.blog_right_sidebar .newsletter_widget .bbtns {
  background: #8d83ff;
  color: #fff;
  font-size: 12px;
  line-height: 38px;
  display: inline-block;
  font-weight: 500;
  padding: 0px 24px 0px 24px;
  border-radius: 0;
}

.blog_right_sidebar .newsletter_widget .text-bottom {
  font-size: 12px;
}

.blog_right_sidebar .tag_cloud_widget ul li {
  display: inline-block;
}

.blog_right_sidebar .tag_cloud_widget ul li a {
  display: inline-block;
  border: 1px solid #eee;
  background: #fff;
  padding: 0px 13px;
  margin-bottom: 8px;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  color: #222222;
  font-size: 12px;
}

.blog_right_sidebar .tag_cloud_widget ul li a:hover {
  background: #8d83ff;
  color: #fff;
}

.blog_right_sidebar .br {
  width: 100%;
  height: 1px;
  background: #eeeeee;
  margin: 30px 0px;
}

.blog-pagination {
  padding-top: 25px;
  padding-bottom: 95px;
}

.blog-pagination .page-link {
  border-radius: 0;
}

.blog-pagination .page-item {
  border: none;
}

.page-link {
  background: transparent;
  font-weight: 400;
}

.blog-pagination .page-item.active .page-link {
  background-color: #8d83ff;
  border-color: transparent;
  color: #fff;
}

.blog-pagination .page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #8a8a8a;
  border: none;
}

.blog-pagination .page-link .lnr {
  font-weight: 600;
}

.blog-pagination .page-item:last-child .page-link,
.blog-pagination .page-item:first-child .page-link {
  border-radius: 0;
}

.blog-pagination .page-link:hover {
  color: #fff;
  text-decoration: none;
  background-color: #8d83ff;
  border-color: #eee;
}

/*============ Start Blog Single Styles  =============*/
.single-post-area .social-links {
  padding-top: 10px;
}

.single-post-area .social-links li {
  display: inline-block;
  margin-bottom: 10px;
}

.single-post-area .social-links li a {
  color: #cccccc;
  padding: 7px;
  font-size: 14px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.single-post-area .social-links li a:hover {
  color: #222222;
}

.single-post-area .blog_details {
  padding-top: 26px;
}

.single-post-area .blog_details p {
  margin-bottom: 10px;
}

.single-post-area .quotes {
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 24px 35px 24px 30px;
  background-color: white;
  -webkit-box-shadow: -20.84px 21.58px 30px 0px rgba(176, 176, 176, 0.1);
  box-shadow: -20.84px 21.58px 30px 0px rgba(176, 176, 176, 0.1);
  font-size: 14px;
  line-height: 24px;
  color: #777;
  font-style: italic;
}

.single-post-area .arrow {
  position: absolute;
}

.single-post-area .arrow .lnr {
  font-size: 20px;
  font-weight: 600;
}

.single-post-area .thumb .overlay-bg {
  background: rgba(0, 0, 0, 0.8);
}

.single-post-area .navigation-area {
  border-top: 1px solid #eee;
  padding-top: 30px;
  margin-top: 60px;
}

.single-post-area .navigation-area p {
  margin-bottom: 0px;
}

.single-post-area .navigation-area h4 {
  font-size: 18px;
  line-height: 25px;
  color: #222222;
}

.single-post-area .navigation-area .nav-left {
  text-align: left;
}

.single-post-area .navigation-area .nav-left .thumb {
  margin-right: 20px;
  background: #000;
}

.single-post-area .navigation-area .nav-left .thumb img {
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}

.single-post-area .navigation-area .nav-left .lnr {
  margin-left: 20px;
  opacity: 0;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}

.single-post-area .navigation-area .nav-left:hover .lnr {
  opacity: 1;
}

.single-post-area .navigation-area .nav-left:hover .thumb img {
  opacity: .5;
}

@media (max-width: 767px) {
  .single-post-area .navigation-area .nav-left {
    margin-bottom: 30px;
  }
}

.single-post-area .navigation-area .nav-right {
  text-align: right;
}

.single-post-area .navigation-area .nav-right .thumb {
  margin-left: 20px;
  background: #000;
}

.single-post-area .navigation-area .nav-right .thumb img {
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}

.single-post-area .navigation-area .nav-right .lnr {
  margin-right: 20px;
  opacity: 0;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}

.single-post-area .navigation-area .nav-right:hover .lnr {
  opacity: 1;
}

.single-post-area .navigation-area .nav-right:hover .thumb img {
  opacity: .5;
}

@media (max-width: 991px) {
  .single-post-area .sidebar-widgets {
    padding-bottom: 0px;
  }
}

.comments-area {
  background: #fafaff;
  border: 1px solid #eee;
  padding: 50px 30px;
  margin-top: 50px;
}

@media (max-width: 414px) {
  .comments-area {
    padding: 50px 8px;
  }
}

.comments-area h4 {
  text-align: center;
  margin-bottom: 50px;
  color: #222222;
  font-size: 18px;
}

.comments-area h5 {
  font-size: 16px;
  margin-bottom: 0px;
}

.comments-area a {
  color: #222222;
}

.comments-area .comment-list {
  padding-bottom: 48px;
}

.comments-area .comment-list:last-child {
  padding-bottom: 0px;
}

.comments-area .comment-list.left-padding {
  padding-left: 25px;
}

@media (max-width: 413px) {
  .comments-area .comment-list .single-comment h5 {
    font-size: 12px;
  }
  .comments-area .comment-list .single-comment .date {
    font-size: 11px;
  }
  .comments-area .comment-list .single-comment .comment {
    font-size: 10px;
  }
}

.comments-area .thumb {
  margin-right: 20px;
}

.comments-area .date {
  font-size: 13px;
  color: #cccccc;
  margin-bottom: 13px;
}

.comments-area .comment {
  color: #777777;
  margin-bottom: 0px;
}

.comments-area .btn-reply {
  background-color: #fff;
  color: #222222;
  border: 1px solid #eee;
  padding: 2px 18px;
  font-size: 12px;
  display: block;
  font-weight: 600;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}

.comments-area .btn-reply:hover {
  background-color: #8d83ff;
  color: #fff;
}

.comment-form {
  background: #fafaff;
  text-align: center;
  border: 1px solid #eee;
  padding: 47px 30px 43px;
  margin-top: 50px;
  margin-bottom: 40px;
}

.comment-form h4 {
  text-align: center;
  margin-bottom: 50px;
  font-size: 18px;
  line-height: 22px;
  color: #222222;
}

.comment-form .name {
  padding-left: 0px;
}

@media (max-width: 767px) {
  .comment-form .name {
    padding-right: 0px;
    margin-bottom: 1rem;
  }
}

.comment-form .email {
  padding-right: 0px;
}

@media (max-width: 991px) {
  .comment-form .email {
    padding-left: 0px;
  }
}

.comment-form .form-control {
  padding: 8px 20px;
  background: #fff;
  border: none;
  border-radius: 0px;
  width: 100%;
  font-size: 14px;
  color: #777777;
  border: 1px solid transparent;
}

.comment-form .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #eee;
}

.comment-form textarea.form-control {
  height: 140px;
  resize: none;
}

.comment-form ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 13px;
  color: #777;
}

.comment-form ::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 13px;
  color: #777;
}

.comment-form :-ms-input-placeholder {
  /* IE 10+ */
  font-size: 13px;
  color: #777;
}

.comment-form :-moz-placeholder {
  /* Firefox 18- */
  font-size: 13px;
  color: #777;
}

/*============ End Blog Single Styles  =============*/
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Reservation Form Area css
============================================================================================ */
.reservation_form_area .res_form_inner {
  max-width: 555px;
  margin: auto;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(153, 153, 153, 0.1);
  box-shadow: 0px 10px 30px 0px rgba(153, 153, 153, 0.1);
  padding: 75px 50px;
  position: relative;
}

.reservation_form_area .res_form_inner:before {
  content: "";

  position: absolute;
  left: -125px;
  height: 421px;
  width: 98px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.reservation_form_area .res_form_inner:after {
  content: "";
  
  position: absolute;
  right: -125px;
  height: 421px;
  width: 98px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.reservation_form .form-group input {
  height: 40px;
  border-radius: 0px;
  border: 1px solid #eeeeee;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0px 15px;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #999999;
}

.reservation_form .form-group input.placeholder {
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #999999;
}

.reservation_form .form-group input:-moz-placeholder {
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #999999;
}

.reservation_form .form-group input::-moz-placeholder {
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #999999;
}

.reservation_form .form-group input::-webkit-input-placeholder {
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #999999;
}

.reservation_form .form-group .res_select {
  height: 40px;
  border: 1px solid #eeeeee;
  border-radius: 0px;
  width: 100%;
  padding: 0px 15px;
  line-height: 36px;
}

.reservation_form .form-group .res_select .current {
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #999999;
}

.reservation_form .form-group .res_select:after {
  content: "\e874";
  font-family: 'Linearicons-Free';
  color: #cccccc;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  border: none;
  margin-top: -17px;
  font-size: 13px;
  right: 22px;
}

.reservation_form .form-group:last-child {
  text-align: center;
}

/* End Reservation Form Area css
============================================================================================ */
/*============== contact_area css ================*/
.mapBox {
  height: 420px;
  margin-bottom: 80px;
}

.contact_info .info_item {
  position: relative;
  padding-left: 45px;
}

.contact_info .info_item i {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 20px;
  line-height: 24px;
  color: #777777;
  font-weight: 600;
}

.contact_info .info_item h6 {
  font-size: 16px;
  line-height: 24px;
  color: "Roboto", sans-serif;
  font-weight: bold;
  margin-bottom: 0px;
  color: #777777;
}

.contact_info .info_item h6 a {
  color: #777777;
}

.contact_info .info_item p {
  font-size: 14px;
  line-height: 24px;
  padding: 2px 0px;
  color: #777777;

}

.contact_form .form-group {
  margin-bottom: 10px;
}

.contact_form .form-group .form-control {
  font-size: 13px;
  line-height: 26px;
  color: #999;
  border: 1px solid #eeeeee;
  font-family: "Roboto", sans-serif;
  border-radius: 0px;
  padding-left: 20px;
}

.contact_form .form-group .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.contact_form .form-group .form-control.placeholder {
  color: #999;
}

.contact_form .form-group .form-control:-moz-placeholder {
  color: #999;
}

.contact_form .form-group .form-control::-moz-placeholder {
  color: #999;
}

.contact_form .form-group .form-control::-webkit-input-placeholder {
  color: #999;
}

.contact_form .form-group textarea {
  resize: none;
}

.contact_form .form-group textarea.form-control {
  height: 140px;
}

.contact_form .submit_btn {
  margin-top: 20px;
  cursor: pointer;
}

/* Contact Success and error Area css
============================================================================================ */
.modal-message .modal-dialog {
  position: absolute;
  top: 36%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%) !important;
  -ms-transform: translateX(-50%) translateY(-50%) !important;
  transform: translateX(-50%) translateY(-50%) !important;
  margin: 0px;
  max-width: 500px;
  width: 100%;
}

.modal-message .modal-dialog .modal-content .modal-header {
  text-align: center;
  display: block;
  border-bottom: none;
  padding-top: 50px;
  padding-bottom: 50px;
}

.modal-message .modal-dialog .modal-content .modal-header .close {
  position: absolute;
  right: -15px;
  top: -15px;
  padding: 0px;
  color: #fff;
  opacity: 1;
  cursor: pointer;
}

.modal-message .modal-dialog .modal-content .modal-header h2 {
  display: block;
  text-align: center;
  color: #8d83ff;
  padding-bottom: 10px;
  font-family: "Roboto", sans-serif;
}

.modal-message .modal-dialog .modal-content .modal-header p {
  display: block;
}

/* End Contact Success and error Area css
============================================================================================ */
/* Home Contact Area css
============================================================================================ */
.home_contact_area {
  overflow: hidden;
}

.home_contact_area .left_img {
  width: calc(50% - 82px);
  float: left;
  overflow: hidden;
}

.home_contact_area .h_right_form {
  width: calc(50% + 82px);
  float: right;
  overflow: hidden;
  padding-left: 95px;
  background-image: -moz-linear-gradient(90deg, #e187f0 0%, #f27fd3 33%, #a276ff 67%, #8889ff 100%);
  background-image: -webkit-linear-gradient(90deg, #e187f0 0%, #f27fd3 33%, #a276ff 67%, #8889ff 100%);
  background-image: -ms-linear-gradient(90deg, #1c358d 0%, #171985 33%, #a276ff 67%, #8889ff 100%);
  background-image: -webkit-linear-gradient(90deg, #0e51a5 0%, #1d3860 33%, #084298 67%, #0c0c38 100%);
  min-height: 680px;
}

.home_contact_area .h_right_form .h_form_inner {
  max-width: 545px;
}

.h_form_inner h4 {
  color: #fff;
  font-size: 36px;
  font-family: "Roboto", sans-serif;
  padding-bottom: 70px;
  padding-top: 120px;
}

.home_contact_form .form-group {
  margin-bottom: 20px;
}

.home_contact_form .form-group input {
  height: 40px;
  background: transparent;
  border: 1px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.2);
  border-radius: 0px;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0px 15px;
  line-height: 50px;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  color: #fff;
}

.home_contact_form .form-group input.placeholder {
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  opacity: .5;
}

.home_contact_form .form-group input:-moz-placeholder {
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  opacity: .5;
}

.home_contact_form .form-group input::-moz-placeholder {
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  opacity: .5;
}

.home_contact_form .form-group input::-webkit-input-placeholder {
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  opacity: .5;
}

.home_contact_form .form-group textarea {
  height: 140px;
  background: transparent;
  border: 1px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.2);
  border-radius: 0px;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 15px 15px;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  resize: none;
}

.home_contact_form .form-group textarea.placeholder {
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  opacity: .5;
}

.home_contact_form .form-group textarea:-moz-placeholder {
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  opacity: .5;
}

.home_contact_form .form-group textarea::-moz-placeholder {
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  opacity: .5;
}

.home_contact_form .form-group textarea::-webkit-input-placeholder {
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  opacity: .5;
}

.home_contact_form .form-group .submit_btn {
  float: right;
}


/* End Home Contact Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*============== Elements Area css ================*/
.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.sample-text-area {
  padding: 100px 0px;
}

.sample-text-area .title_color {
  margin-bottom: 30px;
}

.sample-text-area p {
  line-height: 26px;
}

.sample-text-area p b {
  font-weight: bold;
  color: #8d83ff;
}

.sample-text-area p i {
  color: #8d83ff;
  font-style: italic;
}

.sample-text-area p sup {
  color: #8d83ff;
  font-style: italic;
}

.sample-text-area p sub {
  color: #8d83ff;
  font-style: italic;
}

.sample-text-area p del {
  color: #8d83ff;
}

.sample-text-area p u {
  color: #8d83ff;
}

/*============== End Elements Area css ================*/
/*==============Elements Button Area css ================*/
.elements_button .title_color {
  margin-bottom: 30px;
  color: #222222;
}

.title_color {
  color: #222222;
}

.button-group-area {
  margin-top: 15px;
}

.button-group-area:nth-child(odd) {
  margin-top: 40px;
}

.button-group-area:first-child {
  margin-top: 0px;
}

.button-group-area .theme_btn {
  margin-right: 10px;
}

.button-group-area .white_btn {
  margin-right: 10px;
}

.button-group-area .link {
  text-decoration: underline;
  color: #222222;
  background: transparent;
}

.button-group-area .link:hover {
  color: #fff;
}

.button-group-area .disable {
  background: transparent;
  color: #007bff;
  cursor: not-allowed;
}

.button-group-area .disable:before {
  display: none;
}

.primary {
  background: #52c5fd;
}

.primary:before {
  background: #2faae6;
}

.success {
  background: #4cd3e3;
}

.success:before {
  background: #2ebccd;
}

.info {
  background: #38a4ff;
}

.info:before {
  background: #298cdf;
}

.warning {
  background: #f4e700;
}

.warning:before {
  background: #e1d608;
}

.danger {
  background: #f54940;
}

.danger:before {
  background: #e13b33;
}

.primary-border {
  background: transparent;
  border: 1px solid #52c5fd;
  color: #52c5fd;
}

.primary-border:before {
  background: #52c5fd;
}

.success-border {
  background: transparent;
  border: 1px solid #4cd3e3;
  color: #4cd3e3;
}

.success-border:before {
  background: #4cd3e3;
}

.info-border {
  background: transparent;
  border: 1px solid #38a4ff;
  color: #38a4ff;
}

.info-border:before {
  background: #38a4ff;
}

.warning-border {
  background: #fff;
  border: 1px solid #f4e700;
  color: #f4e700;
}

.warning-border:before {
  background: #f4e700;
}

.danger-border {
  background: transparent;
  border: 1px solid #f54940;
  color: #f54940;
}

.danger-border:before {
  background: #f54940;
}

.link-border {
  background: transparent;
  border: 1px solid #8d83ff;
  color: #8d83ff;
}

.link-border:before {
  background: #8d83ff;
}

.radius {
  border-radius: 3px;
}

.circle {
  border-radius: 20px;
}

.arrow span {
  padding-left: 5px;
}

.e-large {
  line-height: 50px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.large {
  line-height: 45px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.medium {
  line-height: 30px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.small {
  line-height: 25px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.general {
  line-height: 38px;
  padding-top: 0px;
  padding-bottom: 0px;
}

/*==============End Elements Button Area css ================*/
/* =================================== */
/*  Elements Page Styles
/* =================================== */
/*---------- Start Elements Page -------------*/
.generic-banner {
  margin-top: 60px;
  text-align: center;
}

.generic-banner .height {
  height: 600px;
}

@media (max-width: 767.98px) {
  .generic-banner .height {
    height: 400px;
  }
}

.generic-banner .generic-banner-content h2 {
  line-height: 1.2em;
  margin-bottom: 20px;
}

@media (max-width: 991.98px) {
  .generic-banner .generic-banner-content h2 br {
    display: none;
  }
}

.generic-banner .generic-banner-content p {
  text-align: center;
  font-size: 16px;
}

@media (max-width: 991.98px) {
  .generic-banner .generic-banner-content p br {
    display: none;
  }
}

.generic-content h1 {
  font-weight: 600;
}

.about-generic-area {
  background: #fff;
}

.about-generic-area p {
  margin-bottom: 20px;
}

.white-bg {
  background: #fff;
}

.section-top-border {
  padding: 50px 0;
  border-top: 1px dotted #eee;
}

.switch-wrap {
  margin-bottom: 10px;
}

.switch-wrap p {
  margin: 0;
}

/*---------- End Elements Page -------------*/
.sample-text-area {
  padding: 100px 0 70px 0;
}

.sample-text {
  margin-bottom: 0;
}

.text-heading {
  margin-bottom: 30px;
  font-size: 24px;
}

.typo-list {
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .typo-sec {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .element-wrap {
    margin-top: 30px;
  }
}

b, sup, sub, u, del {
  color: #f8b600;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

.typography h1, .typography h2, .typography h3, .typography h4, .typography h5, .typography h6 {
  color: #777777;
}

.button-area .border-top-generic {
  padding: 70px 15px;
  border-top: 1px dotted #eee;
}

.button-group-area .genric-btn {
  margin-right: 10px;
  margin-top: 10px;
}

.button-group-area .genric-btn:last-child {
  margin-right: 0;
}

.circle {
  border-radius: 20px;
}

.genric-btn {
  display: inline-block;
  outline: none;
  line-height: 40px;
  padding: 0 30px;
  font-size: .8em;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.genric-btn:focus {
  outline: none;
}

.genric-btn.e-large {
  padding: 0 40px;
  line-height: 50px;
}

.genric-btn.large {
  line-height: 45px;
}

.genric-btn.medium {
  line-height: 30px;
}

.genric-btn.small {
  line-height: 25px;
}

.genric-btn.radius {
  border-radius: 3px;
}

.genric-btn.circle {
  border-radius: 20px;
}

.genric-btn.arrow {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.genric-btn.arrow span {
  margin-left: 10px;
}

.genric-btn.default {
  color: #222222;
  background: #f9f9ff;
  border: 1px solid transparent;
}

.genric-btn.default:hover {
  border: 1px solid #f9f9ff;
  background: #fff;
}

.genric-btn.default-border {
  border: 1px solid #f9f9ff;
  background: #fff;
}

.genric-btn.default-border:hover {
  color: #222222;
  background: #f9f9ff;
  border: 1px solid transparent;
}

.genric-btn.primary {
  color: #fff;
  background: #f8b600;
  border: 1px solid transparent;
}

.genric-btn.primary:hover {
  color: #f8b600;
  border: 1px solid #f8b600;
  background: #fff;
}

.genric-btn.primary-border {
  color: #f8b600;
  border: 1px solid #f8b600;
  background: #fff;
}

.genric-btn.primary-border:hover {
  color: #fff;
  background: #f8b600;
  border: 1px solid transparent;
}

.genric-btn.success {
  color: #fff;
  background: #4cd3e3;
  border: 1px solid transparent;
}

.genric-btn.success:hover {
  color: #4cd3e3;
  border: 1px solid #4cd3e3;
  background: #fff;
}

.genric-btn.success-border {
  color: #4cd3e3;
  border: 1px solid #4cd3e3;
  background: #fff;
}

.genric-btn.success-border:hover {
  color: #fff;
  background: #4cd3e3;
  border: 1px solid transparent;
}

.genric-btn.info {
  color: #fff;
  background: #38a4ff;
  border: 1px solid transparent;
}

.genric-btn.info:hover {
  color: #38a4ff;
  border: 1px solid #38a4ff;
  background: #fff;
}

.genric-btn.info-border {
  color: #38a4ff;
  border: 1px solid #38a4ff;
  background: #fff;
}

.genric-btn.info-border:hover {
  color: #fff;
  background: #38a4ff;
  border: 1px solid transparent;
}

.genric-btn.warning {
  color: #fff;
  background: #f4e700;
  border: 1px solid transparent;
}

.genric-btn.warning:hover {
  color: #f4e700;
  border: 1px solid #f4e700;
  background: #fff;
}

.genric-btn.warning-border {
  color: #f4e700;
  border: 1px solid #f4e700;
  background: #fff;
}

.genric-btn.warning-border:hover {
  color: #fff;
  background: #f4e700;
  border: 1px solid transparent;
}

.genric-btn.danger {
  color: #fff;
  background: #f44a40;
  border: 1px solid transparent;
}

.genric-btn.danger:hover {
  color: #f44a40;
  border: 1px solid #f44a40;
  background: #fff;
}

.genric-btn.danger-border {
  color: #f44a40;
  border: 1px solid #f44a40;
  background: #fff;
}

.genric-btn.danger-border:hover {
  color: #fff;
  background: #f44a40;
  border: 1px solid transparent;
}

.genric-btn.link {
  color: #222222;
  background: #f9f9ff;
  text-decoration: underline;
  border: 1px solid transparent;
}

.genric-btn.link:hover {
  color: #222222;
  border: 1px solid #f9f9ff;
  background: #fff;
}

.genric-btn.link-border {
  color: #222222;
  border: 1px solid #f9f9ff;
  background: #fff;
  text-decoration: underline;
}

.genric-btn.link-border:hover {
  color: #222222;
  background: #f9f9ff;
  border: 1px solid transparent;
}

.genric-btn.disable {
  color: #222222, 0.3;
  background: #f9f9ff;
  border: 1px solid transparent;
  cursor: not-allowed;
}

.generic-blockquote {
  padding: 30px 50px 30px 30px;
  background: #fff;
  border-left: 2px solid #f8b600;
}

@media (max-width: 991px) {
  .progress-table-wrap {
    overflow-x: scroll;
  }
}

.progress-table {
  background: #fff;
  padding: 15px 0px 30px 0px;
  min-width: 800px;
}

.progress-table .serial {
  width: 11.83%;
  padding-left: 30px;
}

.progress-table .country {
  width: 28.07%;
}

.progress-table .visit {
  width: 19.74%;
}

.progress-table .percentage {
  width: 40.36%;
  padding-right: 50px;
}

.progress-table .table-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.progress-table .table-head .serial, .progress-table .table-head .country, .progress-table .table-head .visit, .progress-table .table-head .percentage {
  color: #222222;
  line-height: 40px;
  text-transform: uppercase;
  font-weight: 500;
}

.progress-table .table-row {
  padding: 15px 0;
  border-top: 1px solid #edf3fd;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.progress-table .table-row .serial, .progress-table .table-row .country, .progress-table .table-row .visit, .progress-table .table-row .percentage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.progress-table .table-row .country img {
  margin-right: 15px;
}

.progress-table .table-row .percentage .progress {
  width: 80%;
  border-radius: 0px;
  background: transparent;
}

.progress-table .table-row .percentage .progress .progress-bar {
  height: 5px;
  line-height: 5px;
}

.progress-table .table-row .percentage .progress .progress-bar.color-1 {
  background-color: #6382e6;
}

.progress-table .table-row .percentage .progress .progress-bar.color-2 {
  background-color: #e66686;
}

.progress-table .table-row .percentage .progress .progress-bar.color-3 {
  background-color: #f09359;
}

.progress-table .table-row .percentage .progress .progress-bar.color-4 {
  background-color: #73fbaf;
}

.progress-table .table-row .percentage .progress .progress-bar.color-5 {
  background-color: #73fbaf;
}

.progress-table .table-row .percentage .progress .progress-bar.color-6 {
  background-color: #6382e6;
}

.progress-table .table-row .percentage .progress .progress-bar.color-7 {
  background-color: #a367e7;
}

.progress-table .table-row .percentage .progress .progress-bar.color-8 {
  background-color: #e66686;
}

.single-gallery-image {
  margin-top: 30px;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
  height: 200px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.single-gallery-image:hover {
  opacity: .8;
}

.list-style {
  width: 14px;
  height: 14px;
}

.unordered-list li {
  position: relative;
  padding-left: 30px;
  line-height: 1.82em !important;
}

.unordered-list li:before {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  border: 3px solid #f8b600;
  background: #fff;
  top: 4px;
  left: 0;
  border-radius: 50%;
}

.ordered-list {
  margin-left: 30px;
}

.ordered-list li {
  list-style-type: decimal-leading-zero;
  color: #f8b600;
  font-weight: 500;
  line-height: 1.82em !important;
}

.ordered-list li span {
  font-weight: 300;
  color: #777777;
}

.ordered-list-alpha li {
  margin-left: 30px;
  list-style-type: lower-alpha;
  color: #f8b600;
  font-weight: 500;
  line-height: 1.82em !important;
}

.ordered-list-alpha li span {
  font-weight: 300;
  color: #777777;
}

.ordered-list-roman li {
  margin-left: 30px;
  list-style-type: lower-roman;
  color: #f8b600;
  font-weight: 500;
  line-height: 1.82em !important;
}

.ordered-list-roman li span {
  font-weight: 300;
  color: #777777;
}

.single-input {
  display: block;
  width: 100%;
  line-height: 40px;
  border: none;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

.single-input:focus {
  outline: none;
}

.input-group-icon {
  position: relative;
}

.input-group-icon .icon {
  position: absolute;
  left: 20px;
  top: 0;
  line-height: 40px;
  z-index: 3;
}

.input-group-icon .icon i {
  color: #797979;
}

.input-group-icon .single-input {
  padding-left: 45px;
}

.single-textarea {
  display: block;
  width: 100%;
  line-height: 40px;
  border: none;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
  height: 100px;
  resize: none;
}

.single-textarea:focus {
  outline: none;
}

.single-input-primary {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

.single-input-primary:focus {
  outline: none;
  border: 1px solid #f8b600;
}

.single-input-accent {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

.single-input-accent:focus {
  outline: none;
  border: 1px solid #eb6b55;
}

.single-input-secondary {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

.single-input-secondary:focus {
  outline: none;
  border: 1px solid #f09359;
}

.default-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.default-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.default-switch input + label {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #f8b600;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.default-switch input:checked + label {
  left: 19px;
}

.single-element-widget {
  margin-bottom: 30px;
}

.primary-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.primary-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.primary-switch input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.primary-switch input + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 8.5px;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.primary-switch input + label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.primary-switch input:checked + label:after {
  left: 19px;
}

.primary-switch input:checked + label:before {
  background: #f8b600;
}

.confirm-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.confirm-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.confirm-switch input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.confirm-switch input + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 8.5px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  cursor: pointer;
}

.confirm-switch input + label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.confirm-switch input:checked + label:after {
  left: 19px;
}

.confirm-switch input:checked + label:before {
  background: #4cd3e3;
}

.primary-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.primary-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.primary-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.single-defination h4 {
  color: #222222;
}

.primary-checkbox input:checked + label {
  background: url(./img/elements/primary-check.png) no-repeat center center/cover;
  border: none;
}

.confirm-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.confirm-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.confirm-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.confirm-checkbox input:checked + label {
  background: url(./img/elements/success-check.png) no-repeat center center/cover;
  border: none;
}

.disabled-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.disabled-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.disabled-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.disabled-checkbox input:disabled {
  cursor: not-allowed;
  z-index: 3;
}

.disabled-checkbox input:checked + label {
  background: url(./img/elements/disabled-check.png) no-repeat center center/cover;
  border: none;
}

.primary-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.primary-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.primary-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.primary-radio input:checked + label {
  background: url(./img/elements/primary-radio.png) no-repeat center center/cover;
  border: none;
}

.confirm-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.confirm-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.confirm-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.confirm-radio input:checked + label {
  background: url(./img/elements/success-radio.png) no-repeat center center/cover;
  border: none;
}

.disabled-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.disabled-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.disabled-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.disabled-radio input:disabled {
  cursor: not-allowed;
  z-index: 3;
}

.unordered-list {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.ordered-list {
  list-style: none;
  padding: 0px;
}

.disabled-radio input:checked + label {
  background: url(./img/elements/disabled-radio.png) no-repeat center center/cover;
  border: none;
}

.default-select {
  height: 40px;
}

.default-select .nice-select {
  border: none;
  border-radius: 0px;
  height: 40px;
  background: #fff;
  padding-left: 20px;
  padding-right: 40px;
}

.default-select .nice-select .list {
  margin-top: 0;
  border: none;
  border-radius: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 100%;
  padding: 10px 0 10px 0px;
}

.default-select .nice-select .list .option {
  font-weight: 300;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  line-height: 28px;
  min-height: 28px;
  font-size: 12px;
  padding-left: 20px;
}

.default-select .nice-select .list .option.selected {
  color: #f8b600;
  background: transparent;
}

.default-select .nice-select .list .option:hover {
  color: #f8b600;
  background: transparent;
}

.default-select .current {
  margin-right: 50px;
  font-weight: 300;
}

.default-select .nice-select::after {
  right: 20px;
}

@media (max-width: 991px) {
  .left-align-p p {
    margin-top: 20px;
  }
}

.form-select {
  height: 40px;
  width: 100%;
}

.form-select .nice-select {
  border: none;
  border-radius: 0px;
  height: 40px;
  background: #f9f9ff !important;
  padding-left: 45px;
  padding-right: 40px;
  width: 100%;
}

.form-select .nice-select .list {
  margin-top: 0;
  border: none;
  border-radius: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 100%;
  padding: 10px 0 10px 0px;
}

.mt-10 {
  margin-top: 10px;
}

.form-select .nice-select .list .option {
  font-weight: 300;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  line-height: 28px;
  min-height: 28px;
  font-size: 12px;
  padding-left: 45px;
}

.form-select .nice-select .list .option.selected {
  color: #f8b600;
  background: transparent;
}

.form-select .nice-select .list .option:hover {
  color: #f8b600;
  background: transparent;
}

.form-select .current {
  margin-right: 50px;
  font-weight: 300;
}

.form-select .nice-select::after {
  right: 20px;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Main Button Area css
============================================================================================ */
.main_btn {
  display: inline-block;
  background: #8d83ff;
  padding: 0px 50px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 48px;
  border-radius: 0px;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  text-align: center;
  border: 1px solid #8d83ff;
  cursor: pointer;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}

.main_btn:hover {
  background: transparent;
  color: #8d83ff;
}

.main_btn2 {
  display: inline-block;
  background: #8d83ff;
  padding: 0px 32px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 38px;
  border-radius: 0px;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  text-align: center;
  border: 1px solid #8d83ff;
  cursor: pointer;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}

.main_btn2:hover {
  background: transparent;
  color: #8d83ff;
}

.submit_btn {
  padding: 0px 40px;
  background-image: -webkit-gradient(linear, left top, right top, from(#73ca96), color-stop(51%, #98d370), to(#73ca96));
  background-image: -webkit-linear-gradient(left, #73ca96 0%, #98d370 51%, #73ca96 100%);
  background-image: -o-linear-gradient(left, #73ca96 0%, #98d370 51%, #73ca96 100%);
  background-image: linear-gradient(to right, #73ca96 0%, #98d370 51%, #73ca96 100%);
  background-size: 200% auto;
  z-index: 2;
  display: inline-block;
  -webkit-transition: all 400ms ease-out;
  -o-transition: all 400ms ease-out;
  transition: all 400ms ease-out;
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  width: auto;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  line-height: 50px;
  border-radius: 5px;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(60, 64, 143, 0.2);
  box-shadow: 0px 10px 20px 0px rgba(60, 64, 143, 0.2);
}

.submit_btn:hover {
  background-position: right center;
  color: #fff;
}

.white_bg_btn {
  display: inline-block;
  background: #f9f9ff;
  padding: 0px 35px;
  color: #222222;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 34px;
  border-radius: 0px;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  text-align: center;
  border: 1px solid #eeeeee;
  cursor: pointer;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}

.white_bg_btn:hover {
  background: #8d83ff;
  color: #fff;
  border-color: #8d83ff;
}

.org_btn {
  padding: 0px 40px;
  background-image: -webkit-gradient(linear, left top, right top, from(#f766a5), color-stop(51%, #f78762), to(#f766a5));
  background-image: -webkit-linear-gradient(left, #f766a5 0%, #f78762 51%, #f766a5 100%);
  background-image: -o-linear-gradient(left, #f766a5 0%, #f78762 51%, #f766a5 100%);
  background-image: linear-gradient(to right, #f766a5 0%, #f78762 51%, #f766a5 100%);
  background-size: 200% auto;
  z-index: 2;
  display: inline-block;
  -webkit-transition: all 400ms ease-out;
  -o-transition: all 400ms ease-out;
  transition: all 400ms ease-out;
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  line-height: 50px;
  border-radius: 5px;
}

.org_btn:hover {
  background-position: right center;
  color: #fff;
}

.green_btn {
  padding: 0px 40px;
  background-image: -webkit-gradient(linear, left top, right top, from(#73ca96), color-stop(51%, #98d370), to(#73ca96));
  background-image: -webkit-linear-gradient(left, #73ca96 0%, #98d370 51%, #73ca96 100%);
  background-image: -o-linear-gradient(left, #73ca96 0%, #98d370 51%, #73ca96 100%);
  background-image: linear-gradient(to right, #73ca96 0%, #98d370 51%, #73ca96 100%);
  background-size: 200% auto;
  z-index: 2;
  display: inline-block;
  -webkit-transition: all 400ms ease-out;
  -o-transition: all 400ms ease-out;
  transition: all 400ms ease-out;
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  line-height: 50px;
  border-radius: 5px;
}

.green_btn:hover {
  background-position: right center;
  color: #fff;
}

/* End Main Button Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Offer Area css
============================================================================================ */
.offer_title {
  
  max-width: 740px;
  margin: 0px auto 70px;
}

.offer_title h5 {
  font-size: 14px;
  text-align: start;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  letter-spacing: 2.1px;
  color: #222222;
  margin-bottom: 15px;
}

.offer_title p {
  text-align: center;
  font-size: 14px;
  line-height: 26px;
  font-family: "Roboto", sans-serif;
  color: #777777;
  font-weight: normal;
  margin-bottom: 0px;
  text-align: justify;
  text-justify:inter-word ;
}

.offer_title p b {
  color: #222222;
}

.offer_item .offer_text {
  text-align: center;
  border: 1px solid #eeeeee;
  padding: 50px 30px 50px 30px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.offer_item .offer_text h4 {
  color: #222222;
  font-size: 18px;
  margin-bottom: 20px;
}

.offer_item .offer_text p {
  margin-bottom: 0px;
  color: #777777;
}

.offer_item:hover .offer_text {
  background-image: -moz-linear-gradient(90deg, #e187f0 0%, #f27fd3 33%, #a276ff 67%, #8889ff 100%);
  background-image: -webkit-linear-gradient(90deg, #e187f0 0%, #f27fd3 33%, #a276ff 67%, #8889ff 100%);
  background-image: -ms-linear-gradient(90deg, #e187f0 0%, #f27fd3 33%, #a276ff 67%, #8889ff 100%);

}


.offer_item:hover .offer_text p {
  color: #fff;
}

/* End Offer Area css
============================================================================================ */
/* Feature Area css
============================================================================================ */
.feature_area {
  background: #f9f9ff;
}

.feature_item {
  border-radius: 10px;
  padding: 60px 20px 60px 30px;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
  background: #fff;
}

.feature_item h4 {
  color: #222222;
  font-size: 18px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  margin-bottom: 25px;
}

.feature_item p {
  margin-bottom: 0px;
}

.feature_item:hover {
  -webkit-box-shadow: 0px 10px 30px 0px rgba(98, 71, 234, 0.1);
  box-shadow: 0px 10px 30px 0px rgba(98, 71, 234, 0.1);
  border-radius: 5px;
  color: #fff;
  background-image: -moz-linear-gradient(90deg, #e187f0 0%, #f27fd3 33%, #a276ff 67%, #8889ff 100%);
  background-image: -webkit-linear-gradient(90deg, #e187f0 0%, #f27fd3 33%, #a276ff 67%, #8889ff 100%);
  background-image: -ms-linear-gradient(90deg, #e187f0 0%, #f27fd3 33%, #a276ff 67%, #8889ff 100%);
}


.feature_item:hover p {
  color: #fff;
}
.feature_item img{
  border-radius: 5px;
}

/* End Feature Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Gallery Area css
============================================================================================ */
.isotope_fillter {
  margin-bottom: 50px;
}

.isotope_fillter .gallery_filter {
  text-align: center;
}

.isotope_fillter .gallery_filter li {
  display: inline-block;
  margin-right: 45px;
}

.isotope_fillter .gallery_filter li:last-child {
  margin-right: 0px;
}

.isotope_fillter .gallery_filter li a {
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  color: #777777;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}

.isotope_fillter .gallery_filter li:hover a, .isotope_fillter .gallery_filter li.active a {
  color: #8d83ff;
}

.gallery_f_inner {
  margin-bottom: -45px;
}

.h_gallery_item {
  display: inline-block;
  margin-bottom: 45px;
}

.h_gallery_item .g_img_item {
  position: relative;
  text-align: center;
  overflow: hidden;
  border-radius: 5px;
}

.h_gallery_item .g_img_item:before {
  content: "";
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background-image: -moz-linear-gradient(90deg, #e187f0 0%, #f27fd3 33%, #a276ff 67%, #8889ff 100%);
  background-image: -webkit-linear-gradient(90deg, #e187f0 0%, #f27fd3 33%, #a276ff 67%, #8889ff 100%);
  background-image: -ms-linear-gradient(90deg, #e187f0 0%, #f27fd3 33%, #a276ff 67%, #8889ff 100%);
  position: absolute;
  opacity: 0;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.h_gallery_item .g_img_item .light {
  position: absolute;
  left: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
  opacity: 0;
}

.h_gallery_item .g_item_text {
  text-align: center;
}

.h_gallery_item .g_item_text h4 {
  color: #222222;
  font-size: 18px;
  margin-top: 22px;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}

.h_gallery_item .g_item_text p {
  margin-bottom: 0px;
}

.h_gallery_item:hover .g_img_item:before {
  opacity: 1;
}

.h_gallery_item:hover .g_img_item .light {
  opacity: 1;
}

.h_gallery_item:hover .g_item_text h4:hover {
  color: #8d83ff;
}

/* End Gallery Area css
============================================================================================ */
/* Portfolio Details Area css
============================================================================================ */
.portfolio_details_inner .row {
  margin-bottom: 65px;
}

.portfolio_details_inner p {
  margin-bottom: 30px;
}

.portfolio_details_inner p:last-child {
  margin-bottom: 0px;
}

.portfolio_details_inner .portfolio_right_text {
  padding-left: 98px;
}

.portfolio_details_inner .portfolio_right_text h4 {
  color: #222222;
  font-size: 24px;
  margin-bottom: 18px;
}

.portfolio_details_inner .portfolio_right_text p {
  margin-bottom: 25px;
}

.portfolio_details_inner .portfolio_right_text .list li {
  margin-bottom: 7px;
  color: #777777;
  font-weight: normal;
  font-size: 14px;
}

.portfolio_details_inner .portfolio_right_text .list li span {
  font-size: 14px;
  color: #222222;
  font-weight: 500;
  width: 97px;
  display: inline-block;
}

.portfolio_details_inner .portfolio_right_text .list li:last-child {
  margin-bottom: 0px;
}

.portfolio_details_inner .portfolio_right_text .list li i {
  color: #f1cd09;
}

/* End Portfolio Details Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* About Area css
============================================================================================ */
.about_area .about_inner .col-lg-6 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.about_right_text {
  padding-left: 68px;
  vertical-align: middle;
  -ms-flex-item-align: center;
  align-self: center;
}

.about_right_text h4 {
  color: #222222;
  font-size: 36px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 25px;
}

.about_right_text p {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  line-height: 24px;
  color: #777777;
  margin-bottom: 18px;
}

.about_right_text p:last-child {
  margin-bottom: 0px;
}

/* End About Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Team Area css
============================================================================================ */
.team_item {
  position: relative;
  text-align: center;
}

.team_item .team_img {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}

.team_item .team_img:before {
  content: "";
  background-image: -moz-linear-gradient(90deg, #e187f0 0%, #f27fd3 33%, #a276ff 67%, #8889ff 100%);
  background-image: -webkit-linear-gradient(90deg, #e187f0 0%, #f27fd3 33%, #a276ff 67%, #8889ff 100%);
  background-image: -ms-linear-gradient(90deg, #e187f0 0%, #f27fd3 33%, #a276ff 67%, #8889ff 100%);
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  opacity: 0;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.team_item .team_img .hover {
  position: absolute;
  left: 0px;
  width: 100%;
  bottom: 40px;
  text-align: center;
  opacity: 0;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}

.team_item .team_img .hover p {
  max-width: 204px;
  margin: auto;
  color: #fff;
  font-size: 12px;
  line-height: 21px;
  padding-bottom: 25px;
  border-bottom: 1px solid #fff;
  margin-bottom: 20px;
}

.team_item .team_img .hover a {
  font-size: 14px;
  color: #fff;
  margin-right: 20px;
  opacity: .3;
}

.team_item .team_img .hover a:last-child {
  margin-right: 0px;
}

.team_item .team_img .hover a:hover {
  opacity: 1;
}

.team_item .team_name {
  text-align: center;
  margin-top: 30px;
}

.team_item .team_name h4 {
  margin-bottom: 5px;
  color: #222222;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 18px;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}

.team_item .team_name h4:hover {
  color: #8d83ff;
}

.team_item .team_name p {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #777777;
  margin-bottom: 0px;
}

.team_item:hover .team_img:before {
  opacity: .90;
}

.team_item:hover .team_img .hover {
  opacity: 1;
}

/* End Team Area css
============================================================================================ */
/* Text Member Area css
============================================================================================ */
.text_members_area {
  background: #f9f9ff;
}

.member_slider .owl-dots {
  text-align: center;
  margin-top: 45px;
}

.member_slider .owl-dots .owl-dot {
  height: 10px;
  width: 10px;
  background: #eeeeee;
  display: inline-block;
  border-radius: 50%;
  margin-right: 8px;
}

.member_slider .owl-dots .owl-dot:last-child {
  margin-right: 0px;
}

.member_slider .owl-dots .owl-dot.active {
  background: #777777;
}

.member_item {
  background: #fff;
  border-radius: 10px;
  padding: 55px 35px 60px 35px;
  text-align: center;
}

.member_item p {
  margin-bottom: 30px;
}

.member_item h4 {
  font-size: 18px;
  color: #222222;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}

.member_item h4:hover {
  color: #8d83ff;
}

.member_item h5 {
  font-size: 14px;
  font-weight: normal;
  color: #777777;
  margin-bottom: 0px;
}

/* End Text Members Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*============== footer-area css ===============*/
.footer-area {
  background: #222222;
  padding-top: 120px;
}

.single-footer-widget p {
  margin-bottom: 0px;
}

.single-footer-widget ul li a {
  line-height: 25px;
  display: inline-block;
  color: #777;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  margin-bottom: 13px;
}

.single-footer-widget ul li a:hover {
  color: #8d83ff;
}

.single-footer-widget .instafeed {
  margin-left: -5px;
  margin-right: -5px;
}

.single-footer-widget .instafeed li {
  margin: 5px;
}

.single-footer-widget .subscribe_form {
  padding-top: 25px;
}

.single-footer-widget .input-group {
  display: block !important;
}

.single-footer-widget input {
  width: 100%;
  border: 1px solid #333333;
  font-size: 13px;
  line-height: 30px;
  padding-right: 40px;
  height: 40px;
  color: #999999;
  background: transparent;
  padding-left: 20px;
}

.single-footer-widget input.placeholder {
  color: #999999;
}

.single-footer-widget input:-moz-placeholder {
  color: #999999;
}

.single-footer-widget input::-moz-placeholder {
  color: #999999;
}

.single-footer-widget input::-webkit-input-placeholder {
  color: #999999;
}

.single-footer-widget input:focus {
  outline: none;
}

.single-footer-widget .sub-btn {
  background: #8d83ff;
  color: #fff;
  font-weight: 300;
  border-radius: 0;
  line-height: 34px;
  padding: 4px 11px 0px;
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 0px;
}

.footer_title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 28px;
}

.border_line {
  width: 100%;
  height: 1px;
  background: #333333;
  margin-top: 100px;
}

.footer-bottom {
  padding-top: 25px;
  padding-bottom: 25px;
}

.footer-bottom .footer-text a {
  color: #8d83ff;
}

.footer-bottom .footer-social {
  text-align: right;
}

.footer-bottom .footer-social a {
  font-size: 18px;
  line-height: 30px;
  color: #82848f;
  margin-left: 25px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.footer-bottom .footer-social a:hover {
  color: #8d83ff;
}

/*---------------------------------------------------- */







/* responsive csss*/

@media (max-width:1619px){
	/* Main Menu Area css
	============================================================================================ */
   .header_area .navbar .search {
		margin-left: 40px;
	}
	/* End Main Menu Area css
	============================================================================================ */
}
@media (max-width:1300px){
	
}
@media (max-width:1199px){
	/* Main Menu Area css
	============================================================================================ */
	.header_area .navbar .nav .nav-item {
		margin-right: 28px;
	}
	/* End Main Menu Area css
	============================================================================================ */
	/* Home Banner Area css
	============================================================================================ */
	.home_banner_area {
		min-height: 700px;
	}
	.home_banner_area .banner_inner {
		min-height: 700px;
	}
	/* End Home Banner Area css
	============================================================================================ */
	.home_contact_area .left_img {
		width: 100%;
		float: none;
	}
	.home_contact_area .left_img img {
		width: 100%;
	}
	.home_contact_area .h_right_form {
		width: 100%;
		float: none;
		min-height: auto;
		padding-left: 0px;
	}
	.home_contact_area .h_right_form .h_form_inner {
		margin: auto;
		padding-bottom: 100px;
	}
	.row.home_contact_form{
		margin-left: 0px;
		margin-right: 0px;
	}
	.h_form_inner h4 {
		padding-left: 15px;
	}
}

@media (max-width:991px){
	/* Main Menu Area css
	============================================================================================ */
    .navbar-toggler {
        border: none;
        border-radius: 0px;
        padding: 0px;
        cursor: pointer;
        margin-top: 27px;
        margin-bottom: 23px;
    }
	.header_area .navbar {
		background: #fff;
	}
    .navbar-toggler[aria-expanded="false"] span:nth-child(2) {
		opacity: 1;
	}
	.navbar-toggler[aria-expanded="true"] span:nth-child(2) {
		opacity: 0;
	}
	.navbar-toggler[aria-expanded="true"] span:first-child {
		transform: rotate(-45deg);
		position: relative;
		top: 7.5px;
	}
	.navbar-toggler[aria-expanded="true"] span:last-child {
		transform: rotate(45deg);
		bottom: 6px;
		position: relative;
	}
	.navbar-toggler span{
		display: block;
		width: 25px;
		height: 3px;
		background: #8d83ff;
		margin: auto;
		margin-bottom: 4px;
		transition: all 400ms linear;
		cursor: pointer;
	}
    .navbar .container{
        padding-left: 15px;
        padding-right: 15px;
    }
    .nav{
        padding: 0px 0px;
    }
	.header_area + section, .header_area + row, .header_area + div {
		margin-top: 117px;
	}
    .header_top .nav{
        padding: 0px;
    }
    .header_area .navbar .nav .nav-item .nav-link{
        line-height: 40px;
        margin-right: 0px;
        display: block;
		border-bottom: 1px solid #ededed33;
		border-radius: 0px;
    }
    .header_area .navbar .search{
        margin-left: 0px;
    }
	.header_area .navbar-collapse{
		max-height: 340px;
		overflow-y: scroll;
	}
	.header_area .navbar .nav .nav-item.submenu ul .nav-item .nav-link {
		padding: 0px 15px;
	}
	.header_area .navbar .nav .nav-item {
		margin-right: 0px;
	}
	.header_area + section, .header_area + row, .header_area + div {
		margin-top: 71px;
	}
	.header_area.navbar_fixed .main_menu .navbar .nav .nav-item .nav-link {
		line-height: 40px;
	}
	/* End Main Menu Area css
	============================================================================================ */
	/* Blog page Area css
	============================================================================================ */
	.categories_post img{
        width: 100%;
    }
	.categories_post {
		max-width: 360px;
		margin: 0 auto;
	}
	.blog_categorie_area .col-lg-4{
        margin-top: 30px;
    }
    .blog_area{
        padding-bottom: 80px;
    }
    .single-post-area .blog_right_sidebar{
        margin-top: 30px;
    }
	/* End Blog page Area css
	============================================================================================ */
	
	/* Contact Page Area css
	============================================================================================ */
	.contact_info{
		margin-bottom: 50px;
	}
	/* End Contact page Area css
	============================================================================================ */
	.home_banner_area .donation_inner{
		margin-bottom: -30px;
	}
	.home_banner_area .dontation_item{
		max-width: 350px;
		margin: auto;
	}
	/* Footer Area css
	============================================================================================ */
	.footer-area .col-sm-6{
		margin-bottom: 30px;
	}
	/* End End Footer Area css
	============================================================================================ */
	.home_banner_area {
		background-size: cover;
		margin-bottom: 0px;
	}
	.team_inner{
		margin-bottom: -30px;
	}
	.team_item{
		max-width: 263px;
		margin: 0px auto 30px;
	}
	.services_item{
		max-width: 360px;
		margin: 0px auto 30px;
	}
	.offer_item{
		max-width: 360px;
		margin: 0px auto 30px;
	}
	.offer_inner{
		margin-bottom: -30px;
	}
	.feature_item{
		margin-bottom: 30px;
	}
	.feature_inner{
		margin-bottom: -30px;
	}
	.isotope_fillter .gallery_filter li {
		margin-right: 15px;
	}
	.l_blog_item{
		max-width: 263px;
		margin: 0px auto 30px; 
	}
	.latest_blog_inner{
		margin-bottom: -30px;
	}
	.about_right_text {
		padding-left: 0px;
		padding-top: 50px;
	}
	.portfolio_details_inner .portfolio_right_text {
		padding-left: 0px;
	}
}
@media (max-width:767px){
	.home_banner_area {
		min-height: 500px;
	}
	.home_banner_area .banner_inner {
		min-height: 500px;
	}
	.home_banner_area .banner_inner .banner_content {
		margin-top: 0px;
	}
	/* Blog Page Area css
	============================================================================================ */
    .blog_banner .banner_inner .blog_b_text h2 {
		font-size: 40px;
		line-height: 50px;
	}
	.blog_info.text-right{
		text-align: left !important;
		margin-bottom: 10px;
	}
	/* End Blog Page Area css
	============================================================================================ */
	.home_banner_area .banner_inner .banner_content h3 {
		font-size: 30px;
	}
	.home_banner_area .banner_inner .banner_content p br {
		display: none;
	}
	.home_banner_area .banner_inner .banner_content h3 span {
		line-height: 45px;
		padding-bottom: 0px;
		padding-top: 0px;
	}
	/* Footer Area css
	============================================================================================ */
	.footer-bottom{
		text-align: center;
	}
	.footer-bottom .footer-social {
		text-align: center;
		margin-top: 15px;
	}
	/* End End Footer Area css
	============================================================================================ */
	.footer_area .container.box_1620{
		width: 100%;
		max-width: 100%;
		padding: 0px;
	}
	.footer_inner .f_menu li {
		margin-right: 18px;
	}
	.footer_inner{
		padding-left: 15px;
		padding-right: 15px;
	}
	.isotope_fillter .gallery_filter li {
		margin-bottom: 15px;
	}
	.portfolio_details_inner .portfolio_right_text {
		padding-top: 40px;
	}
}
@media (max-width:600px){
	
}
@media (max-width:575px){
	.top_menu {
		display: none;
	}
	.header_area + section, .header_area + row, .header_area + div {
		margin-top: 71px;
	}
    /* Home Banner Area css
	============================================================================================ */
	.home_banner_area .banner_inner .banner_content h2 {
		font-size: 28px;
	}
	.home_banner_area {
		min-height: 500px;
	}
	.home_banner_area .banner_inner {
		min-height: 500px;
	}
	.blog_banner .banner_inner .blog_b_text {
		margin-top: 0px;
	}
	.home_banner_area .banner_inner .banner_content img{
		display: none;
	}
	.home_banner_area .banner_inner .banner_content h5 {
		margin-top: 0px;
	}
	.banner_area .banner_inner .banner_content .page_link a {
		margin-right: 16px;
	}
	.banner_area .banner_inner .banner_content .page_link a::before {
		right: -13px;
	}
	/* End Home Banner Area css
	============================================================================================ */
	.p_120 {
		padding-top: 70px;
		padding-bottom: 70px;
	}
	.main_title h2 {
		font-size: 25px;
	}
	/* Elements Area css
	============================================================================================ */
	.sample-text-area {
		padding: 70px 0 70px 0;
	}
	.generic-blockquote {
		padding: 30px 15px 30px 30px;
	}
	/* End Elements Area css
	============================================================================================ */
	
	/* Blog Page Area css
	============================================================================================ */
	.blog_details h2 {
		font-size: 20px;
		line-height: 30px;
	}
	.blog_banner .banner_inner .blog_b_text h2 {
		font-size: 28px;
		line-height: 38px;
	}
	/* End Blog Page Area css
	============================================================================================ */
	/* Footer Area css
	============================================================================================ */
	.footer-area {
		padding: 70px 0px;
	}
	/* End End Footer Area css
	============================================================================================ */
	.pad_top {
		padding-top: 70px;
	}
	.gallery_f_inner{
		max-width: 340px;
		margin: 0px auto -30px; 
	}
	.h_form_inner h4 {
		padding-top: 70px;
		padding-bottom: 40px;
		font-size: 30px;
	}
	.home_contact_area .h_right_form .h_form_inner {
		padding-bottom: 50px;
	}
	.footer-bottom {
		padding-bottom: 0px;
	}
	.border_line {
		margin-top: 50px;
	}
}

@media (max-width:480px){
	/* Main Menu Area css
	============================================================================================ */
	.header_area .navbar-collapse{
		max-height: 250px;
	}
	/* End Main Menu Area css
	============================================================================================ */
	
	/* Home Banner Area css
	============================================================================================ */
    .home_banner_area .banner_inner .banner_content {
		padding: 30px 15px;
		margin-top: 0px;
	}
	.banner_content .white_btn {
		display: block;
	}
	.home_banner_area .banner_inner .banner_content h3 {
		font-size: 24px;
		line-height: 30px;
	}
	/* End Home Banner Area css
	============================================================================================ */
	.banner_area .banner_inner .banner_content h2 {
		font-size: 32px;
	}
	/* Blog Page Area css
	============================================================================================ */
	.comments-area .thumb {
		margin-right: 10px;
	}
	
	/* End Blog Page Area css
	============================================================================================ */
	.home_banner_area {
		min-height: 400px;
	}
	.home_banner_area .banner_inner {
		min-height: 400px;
	}
	.counter_inner .counter_item {
		flex: 0 0 100%;
		max-width: 100%;
		text-align: center;
	}
	.home_banner_area .banner_inner .banner_content .org_btn {
		margin-right: 0px;
		margin-bottom: 15px;
	}
}
.offer_item .offer_text {
  text-align: center;
  border: 1px solid #eeeeee;
  padding: 50px 30px 50px 30px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.navlink{
  text-transform: uppercase;
  color: #003289;
  text-decoration: none;
  font-weight: bold;
  font-family:'Courier New', Courier, monospace;
}
.navlink:hover{
  background-color: #003289;
  color:#cccccc;
  padding: 5px;
  border-radius: 6px;
  transform: scale(1);
}
.active-link{

color: #222222;
}
.cardtitle{
  text-transform: uppercase;
  color: #04091e;
}

.img-fluid:hover{
  transform: scale(1.3);
}

.footerlink{
  text-decoration: none;
  font-weight: bold;
  font-family:'Courier New', Courier, monospace;
}

